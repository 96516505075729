import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const PaymentScheduled: FC<IN3oIconProps> = ({
  height,
  width,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.24388 4.99185C2.5218 6.50406 2.81408 8.33322 3.94201 9.504L3.94555 9.50768L8.61567 14.4139C8.93933 14.7539 8.92607 15.292 8.58604 15.6156C8.24601 15.9393 7.70799 15.926 7.38432 15.586L2.71588 10.6815C1.08558 8.98766 0.689269 6.39261 1.71157 4.25562L1.71405 4.25043C3.06935 1.46593 6.34617 0.323193 9.04015 1.79117C9.52296 2.05426 9.96392 2.39138 10.349 2.7902L10.9998 3.41811L11.6507 2.7901C13.7897 0.576316 17.2396 0.610717 19.3382 2.86742C19.719 3.27683 20.0377 3.7432 20.2847 4.25019L20.2869 4.25471C20.787 5.29688 20.8975 6.0651 20.6727 7.30364C20.5888 7.76553 20.1464 8.072 19.6845 7.98815C19.2226 7.9043 18.9162 7.46189 19 7C19.1424 6.21566 19.1509 5.81763 18.7554 4.99261C18.581 4.63507 18.3574 4.30911 18.0933 4.02512C16.6549 2.4783 14.3256 2.45925 12.8651 3.97985L12.8539 3.99151L10.9998 5.78038L9.14566 3.9914L9.13435 3.97962C8.86513 3.69911 8.55889 3.46493 8.22673 3.28393C6.43389 2.307 4.2004 3.03005 3.24388 4.99185Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0318 9C13.8032 9 13.6103 9.18442 13.6103 9.42V10.08H11.358C10.9271 10.08 10.5718 10.4251 10.5718 10.86V20.5C10.5718 21.0794 10.89 21.6 11.5 21.6H22.5395C22.9704 21.6 23.3257 21.2549 23.3257 20.82V10.86C23.3257 10.4251 22.9704 10.08 22.5395 10.08H20.2872V9.42C20.2872 9.18442 20.0943 9 19.8657 9H19.0149C18.7863 9 18.5934 9.18442 18.5934 9.42V10.08H15.3041V9.42C15.3041 9.18442 15.1112 9 14.8826 9H14.0318ZM12.2657 19.92H21.6318V14.82H12.2657V19.92ZM12.2657 11.76V13.2H21.6318V11.76H20.2872V12.18C20.2872 12.4156 20.0943 12.6 19.8657 12.6H19.0149C18.7863 12.6 18.5934 12.4156 18.5934 12.18V11.76H15.3041V12.18C15.3041 12.4156 15.1112 12.6 14.8826 12.6H14.0318C13.8032 12.6 13.6103 12.4156 13.6103 12.18V11.76H12.2657Z"
      fill={fill}
    />
  </svg>
);
