import React, { FC } from "react";

import { IN3oIconProps } from "../types";

export const ArrowLeftRight: FC<IN3oIconProps> = ({ height, width, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={fill}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="M8 3 4 7l4 4" fill={fill} />
    <path d="M4 7h16" fill={fill} />
    <path d="m16 21 4-4-4-4" fill={fill} />
    <path d="M20 17H4" fill={fill} />
  </svg>
);
