import { IConfig } from "@n3oltd/k2.subscriptions.sdk.subscriptions";

import AppManager from "appRedux/AppManager";
import AuthManager from "appRedux/models/auth/AuthManager";
import { FlatRoutes as Q1FlatRoutes } from "appRedux/models/routes/Q1RouteModel";
import {
  InternalRoutes,
  FlatRoutes as K2FlatRoutes,
} from "appRedux/models/routes/RouteModel";

import { ApiResponseModel } from "..";
import {
  IApiResponse,
  K2StatusCodes,
  ProblemDetails,
  ServerError,
} from "../common/ApiResponseModel";

class K2RestService {
  private successResponseClient = <T>(res: T) => {
    return new ApiResponseModel(res);
  };

  public readonly defaultClientConfiguration: IConfig = {
    getAuthorization: () => `Bearer ${AuthManager.getToken()}`,
    getN3OSubscription: () => AuthManager.getSubscriptionId(),
    getN3OAppId: () => AppManager.getAppId(),
    getN3ORouteKey: () => K2RestService.getCurrentRouteKey(),
    getN3OIgnoreValidationWarnings: () => null,
    getN3OClockAdjustment: () => null,
    getN3OServiceCache: () => null,
    getN3OAccountActivityScopeId: () => null,
  };

  public static getCurrentRouteKey = (): string => {
    const windowsPathname = window.location.pathname;

    const route = Object.entries(K2FlatRoutes)
      .concat(Object.entries(Q1FlatRoutes))
      .find((r) => {
        if (AppManager.findMatchPath(windowsPathname, r[1])) return r;
        else return null;
      });

    if (route) return route[0];
    else return null;
  };

  getAlternativeRouteKeyForInternalRoute = (): string => {
    const windowsPathname = window.location.pathname;

    const internalRoute = InternalRoutes.find((r) => {
      if (AppManager.findMatchPath(windowsPathname, r.routePath)) return r;
      else return null;
    });

    if (internalRoute) return internalRoute.apiRouteKey;
    else return null;
  };

  private errorResponseClient = (restError: ProblemDetails) => {
    // 500 Errors are not returned by the clients already parsed into ProblemDetails, so attempt to parse them here
    if (restError.response) {
      try {
        restError = JSON.parse(restError.response);
      } catch (e) {
        console.log(e);
      }
    }

    const serverError = new ServerError(restError, restError?.status);
    return new ApiResponseModel(null, serverError);
  };

  toResponse = <T>(p: Promise<T>): Promise<IApiResponse<T>> => {
    return p
      ?.then((r) => {
        const isUnauthorized =
          // @ts-ignore
          r?._response?.status === K2StatusCodes.unAuthorized;

        if (isUnauthorized)
          return Promise.reject({
            ...r,
            statusCode: K2StatusCodes.unAuthorized,
          });

        return this.successResponseClient(r);
      })
      ?.catch(this.errorResponseClient);
  };
}

export default new K2RestService();
